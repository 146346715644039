import React, { FC, memo } from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import { Trans } from '@lingui/react';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import GreenCarImage from '../../../public/static/compressedImages/homepage/earn-teaser.jpg';
import { ArrowLink, ArrowLinkDesktopVisible } from './styles';
import { routes } from '~/src/utils/routing';
import LocalizedLink from '~/src/components/LocalizedLink';

const HowToStartBlockENCZ: FC = () => (
  <EarnBox>
    <EarnBoxImageWrapper>
      <Image alt='Mountains' src={GreenCarImage} layout='fill' objectFit='cover' quality={70} />
    </EarnBoxImageWrapper>

    <EarnBoxContent>
      <EarnBoxPreHead>
        <Trans id='mainPage.howToStart.earnBoxPreHead' message='vydělávejte s campiri' />
      </EarnBoxPreHead>
      <EarnBoxHead>
        <Trans
          id='mainPage.howToStart.earnBoxHead'
          message='Vlastníte karavan? Vydělávejte až 300.000 Kč ročně na jeho pronájmu.'
        />
      </EarnBoxHead>
      <EarnListBenefits>
        <EarnListBenefitsItem>
          <i className='icon-check' />
          <Trans id='mainPage.howToStart.earnListBenefitsItem.freeInput' message='Vložení zdarma' />
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className='icon-check' />
          <Trans id='mainPage.howToStart.earnListBenefitsItem.availability' message='Dostupnost dle vás' />
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className='icon-check' />
          <Trans id='mainPage.howToStart.earnListBenefitsItem.insurance' message='Kompletní pojištění' />
        </EarnListBenefitsItem>
        <EarnListBenefitsItem>
          <i className='icon-check' />
          <Trans id='mainPage.howToStart.earnListBenefitsItem.renters' message='Ověření nájemníci' />
        </EarnListBenefitsItem>
      </EarnListBenefits>
      <LocalizedLink href={routes.listingWizardEntry} passHref={true}>
        <ArrowLink>
          <Trans id='mainPage.howToStart.earnListBenefitsItem.findOutMore' message='Zjistit více' />{' '}
          <ArrowLinkDesktopVisible>
            <Trans id='mainPage.howToStart.earnListBenefitsItem.aboutRenting' message='o pronajímání' />
          </ArrowLinkDesktopVisible>
          <i className='icon-action-forward' />
        </ArrowLink>
      </LocalizedLink>
    </EarnBoxContent>
  </EarnBox>
);

const EarnBox = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  @media ${deviceMaxWidth.tablet} {
    height: auto;
    flex-direction: column;
  }
`;

const EarnBoxImageWrapper = styled.div`
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  overflow: hidden;
  position: relative;
  width: 53.8461538%;
  position: relative;
  @media ${deviceMaxWidth.tablet} {
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  @media ${deviceMaxWidth.xs} {
    height: 176px;
  }
`;

const EarnBoxContent = styled.div`
  width: 46.1538462%;
  padding: 32px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  background-color: var(--color-beige);
  @media ${deviceMaxWidth.tablet} {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  @media ${deviceMaxWidth.xs} {
    padding: 20px 12px 20px 12px;
  }
`;

const EarnBoxPreHead = styled.span`
  display: inline-block;
  margin: 0 0 12px 0;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: var(--color-orange);
  text-transform: uppercase;
  @media ${deviceMaxWidth.xs} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
  }
`;

const EarnBoxHead = styled.h3`
  margin: 0 0 20px 0;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1f2244;
  max-width: 414px;
  @media ${deviceMaxWidth.xs} {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
  }
`;

const EarnListBenefits = styled.ul`
  list-style: none;
  columns: 2;
  margin-bottom: 18px;
  @media ${deviceMaxWidth.xs} {
    columns: 1;
    margin-bottom: 12px;
  }
`;

const EarnListBenefitsItem = styled.li`
  margin: 0 0 12px 0;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1f2244;
  display: flex;
  align-items: center;
  .icon-check {
    @media ${deviceMaxWidth.xs} {
      font-size: 1.2rem;
    }
    margin-right: 12px;
    &:before {
      color: #119383;
    }
  }
`;
HowToStartBlockENCZ.displayName = 'HowToStartBlockENCZ';
export default memo(HowToStartBlockENCZ);
